const NotFound = () => {
    return ( 

    <main className='container'>
        <div className="row justify-content-center mt-3 mb4">
        <div className="col-8">

            <h1 className="text-danger">404 -  Not Found</h1>
        </div>
    </div>
</main>
    )
}

export default NotFound